<template>
  <Chatbot></Chatbot>
</template>

<script>
import Chatbot from "./views/Chatbot/index.vue";


export default {
  name: "App",
  components: {
    Chatbot,
  },
};
</script>

<style>
</style>
