// import Cookies from 'js-cookie'

const TOKENKEY = document.referrer + 'megaview';
// const CODEKEY = 'chatbot_code';

// export function getToken() {
//   return Cookies.get(TOKENKEY)
// }
// export function setToken(token) {
//   return Cookies.set(TOKENKEY,token)
// }
// export function setCode(code){
//   return Cookies.set(CODEKEY,code)
// }
// export function getCode(){
//   return Cookies.get(CODEKEY)
// }
export default {
  TOKENKEY,
  CODE:''
}