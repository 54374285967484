<template>
  <div class="bubble-multioptions">
    <div
      class="bubble-customer"
      v-for="(item, index) in newOptions"
      :key="index"
    >
      <div class="bubble">
        <el-checkbox v-model="item.isChecked" class="bubble-checkbox"></el-checkbox>
        {{ item.label }}
      </div>
    </div>
    <div class="bubble-customer" v-show="buttonShow">
      <el-button type="primary" size="small" @click="submit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {computed, inject, ref} from 'vue';
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props,{emit}) {
    const newOptions = ref(props.options);
    const buttonShow = computed(()=>newOptions.value.some(item=>item.isChecked))
    const submit = ()=>{
      const selections = []
     for(const item of newOptions.value){
        if(item.isChecked){
          selections.push(item.value)
        }
      }
      emit('multiChoosed',selections)
        newOptions.value = []
    }
    return {
      newOptions,
      buttonShow,
      submit
    }
  },
};
</script>

<style lang="scss" scoped>
.bubble-multioptions {
  margin: 25px 0;
}
.bubble-customer {
  justify-content: flex-end;
  padding-left: 20px;
  display: flex;
  margin: 8px 0;
  .bubble {
    border-radius: 6px 0px 6px 6px;
    padding: 10px 12px;
    background: #f5f7fa;
    transition: 0.1s;
    display: flex;
    align-items: center;
    .bubble-checkbox{
      margin-right: 10px;
    }
  }
}
</style>