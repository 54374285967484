<template>
  <div class="bubble-customer">
    <div class="bubble" >
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "ChatBubble",
};
</script>

<style lang="scss" scoped>
.bubble-customer {
  justify-content: flex-end;
  padding-left: 20px;
  margin: 8px 0;
  display: flex;
  .bubble {
    border-radius: 6px 0px 6px 6px;
    padding: 10px 12px;
    background: #f5f7fa;
    justify-content: flex-end;
  }
}
</style>