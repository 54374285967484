import { createApp } from 'vue'
import {ElCheckbox,ElButton,ElMessage} from 'element-plus'
import App from './App.vue'

import './styleSheet/index.scss'
// import './styleSheet/element-variables.scss';
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')
const app = createApp(App)
app.use(ElCheckbox)
app.use(ElButton)
app.use(ElMessage)
app.config.globalProperties.$day = dayjs

app.mount('#app')

