<template>
  <div class="chat-body" ref="chatBody">
    <transition-group name="fade-list" tag="div" class="container">
      <template v-for="(item, index) in logList">
        <BubbleService v-if="item.type === 'service'" :key="index">{{
          item.content
        }}</BubbleService>
        <BubbleCustomer v-if="item.type === 'customer'" :key="index">{{
          item.content
        }}</BubbleCustomer>
        <BubbleOptions
          v-if="item.type === 'options'"
          :key="index"
          :options="item.options"
          @choosed="singleChoosed"
        ></BubbleOptions>
        <Appointment
          v-if="item.type === 'appoint'"
          v-bind="item.content"
          :key="index"
          @succeed="appointSucceed"
          @failed="appointFailed"
        ></Appointment>
        <BubbleMultiOptions
          v-if="item.type === 'multioptions'"
          :key="index"
          :options="item.options"
          @multiChoosed="multiChoosed"
        ></BubbleMultiOptions>
      </template>
    </transition-group>
  </div>
</template>

<script>
import BubbleService from "components/Bubbles/BubbleService";
import BubbleCustomer from "components/Bubbles/BubbleCustomer";
import Appointment from "components/Appointment/index";
import BubbleOptions from "components/Bubbles/BubbleOptions";
import BubbleMultiOptions from "components/Bubbles/BubbleMultiOptions";
import { ref, toRefs, watch, nextTick, onMounted } from "vue";

export default {
  name: "ChatBody",

  props: {
    logList: Array,
  },
  components: {
    BubbleService,
    BubbleCustomer,
    Appointment,
    BubbleOptions,
    BubbleMultiOptions,
  },

  setup(props, { emit }) {
    const { logList } = toRefs(props);
    const chatBody = ref(null);
    const renewScroll = () => {
      nextTick(() => {
        chatBody.value.scrollTop = chatBody.value.scrollHeight;
      });
    };
    watch(logList.value, (val) => {

      renewScroll();
    });

    const appointSucceed = (val1, val2, val3) => {
      emit("appointSucceed", val1, val2, val3);
    };
    const singleChoosed = (val) => {
      emit("singleChoosed", val);
    };
    const multiChoosed = (val) => {
      emit("multiChoosed", val);
    };
    const appointFailed = (val1, val2) => {
      emit("appointFailed", val1, val2);
    };
    return {
      chatBody,
      appointSucceed,
      singleChoosed,
      multiChoosed,
      appointFailed,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat-body {
  padding: 20px;
  overflow: auto;
  .container {
    > div {
      transition: all 0.2s ease-in-out;
    }
  }
}
.fade-list-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.fade-list-leave-to {
  // opacity: 1;
  // transform: translateY(-30px);
}
</style>