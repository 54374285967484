import request from '@/utils/request';
export function getBotConfig(){
  return request({
    url:'/info',
    method:'get',
  })
}
export function getTokenAsync(data){
  return request({
    url:`/token?is_trial=${data}`,
    method:'post',
  })
}
export function sendEvent(data){
  return request({
    url:'/send',
    method:'post',
    data
  })
}
export function getHistory(){
  return request({
    url:'/history',
    method:'get'
  })
}