import axios from 'axios'
import consts from './auth'
// create an axios instance
const baseURL = location.hostname==='chatbot.megaview.com'?'https://app.megaview.com/api/chat_bot/chat':'https://jirui.dev.mgvai.cn/api/chat_bot/chat';
const service = axios.create({
  baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})


// request interceptor
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      const parmasObj = config.data
      const formData = new FormData()
      if (parmasObj) {
        Object.keys(parmasObj).forEach(key => {
          if (Array.isArray(parmasObj[key])) {
            for (let i = 0; i < parmasObj[key].length; i++) {
              //假设这个数组对应的字段为box
              formData.append(`${key}`, parmasObj[key][i])
            }
          } else {
            formData.append(key, parmasObj[key])
          }
        })
      }
      config.data = formData;
    }

  // const {code} = localStorage;
  const token = localStorage[consts.TOKENKEY]
  const code = consts.CODE
    if(config.url==='/token?is_trial=true'||config.url==='/token?is_trial=false'){
      config.url+=`&chatbot_code=${code}`
    }else{
      config.url+=`?chatbot_code=${code}&chatbot_token=${token}`
    }
    return config
  },
  error => {

    return Promise.reject(error)
  }
) 

service.interceptors.response.use(
  response=>{
    return response.data

  }
)

export default service
