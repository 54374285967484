import { sendEvent } from "api/robot.js";
import {  reactive, ref, inject, toRefs, computed } from "vue";

export function useInit(){
  const activeType = ref("date")
  const backTo = (type)=>{activeType.value = type}
  const handleAvatar = (str)=>{
    const pattern = new RegExp("[\u4E00-\u9FA5]+");
    return pattern.test(str)?str.substring(str.length-2):str.substring(0,4)
  }
  return {activeType,backTo,handleAvatar}
}

export function useDateOptions(dayjs,dateList) {

  const dateOptions = ref([]);
  for(const item of dateList){
    dateOptions.value.push({
      val:item.date,
      date:dayjs(item.date).format("M月 DD日 ddd"),
      isAvailable:item.is_available
    })
  }
  return { dateOptions };
}

export function useTimeOptions(dayjs,activeType,emit){
  const choosedDate = ref('');
  const timeOptions = ref([]);
  const startIndex = ref(0);
  const shortTimeOptions = computed(()=>timeOptions.value.slice(startIndex.value,startIndex.value+4));
  const handleDateChoose = async (date)=>{
    choosedDate.value = date.date;
    const res = await sendEvent({ event: "appoint_meeting_pre",content:date.val });
    activeType.value = 'time';
    const { content,next_event } = res.results[0];
    if(next_event==='appoint_meeting'){
      for(const item of content){
        item.label = dayjs(item.time_begin).format('HH:mm')
      }
      timeOptions.value = content
      startIndex.value = 0
    }else{
      const {content,next_event}=res.results[0]
      emit('failed',next_event,res.results[0])
   
    }

  }  
  const flip = (num)=>{
    startIndex.value += 4*num
  }
  return {choosedDate,timeOptions,shortTimeOptions,startIndex,handleDateChoose,flip}    
}

export function useTimeChoose(activeType,emit){
  const pushLog = inject('pushLog')
  const meetingInfo = reactive({
    hostName:'',
    meetingDate:'',
  })
  const handleTimeChoose =  async (begin_time,user_id)=>{
    activeType.value = 'success'
    const params = {
      content:JSON.stringify({begin_time,user_id}),
      event:'appoint_meeting'
    }
    const res = await sendEvent(params)
    if(res.code===200){
      const {content} = res.results[0];
      meetingInfo.hostName = content.user_name;
      meetingInfo.meetingDate = content.begin_time;
      setTimeout(()=>{
        pushLog({type:'service',content:res.results[1].content})
      },300)
      setTimeout(()=>{
        emit('succeed',res.results[2].next_event,res.results[2],meetingInfo)
      },600)

    }

  }
  return {
    ...toRefs(meetingInfo),
    handleTimeChoose
  }
}
export function useHistory(props,hostName,meetingDate,activeType){
  if(props.isComplete){
    hostName.value = props.meetingInfo.hostName;
    meetingDate.value = props.meetingInfo.meetingDate
    activeType.value = 'success'
  }
}