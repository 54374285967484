<template>
  <div class="bubble-options">
    <div
      class="bubble-customer"
      v-for="(item, index) in newOptions"
      :key="index"
    >
      <div class="bubble" :class="{ choosed }" @click="handleClick(item)">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref} from 'vue';
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props,{emit}) {
    const newOptions = ref(props.options);
    const choosed = ref(false)
    const handleClick = (item)=>{
      if(!choosed.value){
        choosed.value = true;
        emit('choosed',item)
      }
    }
    return {
      newOptions,
      choosed,
      handleClick
    }
  },
};
</script>

<style lang="scss" scoped>
.bubble-options {
  margin: 25px 0;
}
.bubble-customer {
  justify-content: flex-end;
  padding-left: 20px;
  display: flex;
  margin: 8px 0;
  .bubble {
    border-radius: 6px 0px 6px 6px;
    padding: 10px 12px;
    background: #f5f7fa;
    transition: 0.1s;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>