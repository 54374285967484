<template>
  <div class="chat-bot" :style="styleVar">
    <header class="header">{{ appName }}</header>
    <ChatBody
      :appColor="appColor"
      :logList ="logList"
      @appointSucceed="appointSucceed"
      @singleChoosed="singleChoosed"
      @multiChoosed="multiChoosed"
      @appointFailed="eventTrigger"
    ></ChatBody>
    <footer>
      <p class="sign-row">
        由<span class="sign" @click="blink"> megaview.com </span>驱动
      </p>
      <ChatInput
        :mainColor="colorMain"
        :inputEvent="inputEvent"
        v-show="inputShow"
        @submit="submit"
      ></ChatInput>
    </footer>
    <!-- <div><el-button type="primary" size="mini" @click="reTest">重新开始</el-button></div> -->
    
  </div>
</template>

<script>
import ChatBody from "components/ChatBody";
import ChatInput from "components/ChatInput";
import {  ref,provide, onBeforeUnmount, onMounted, watchEffect,watch } from 'vue'
import { useIdentification, useAppConfig,useInput,useSubmit,useEventTriggers,useHandleOptions,useHistory,useCommand } from "./hooks";

export default {
  name: "Chatbot",
  components: {
    ChatBody,
    ChatInput,
  },
  setup() {
    const logList = ref([])
    const currentEvent = ref('');
    const nextEvent = ref('');
    const pushLog = (val)=>{
      logList.value.push(val)
      };
    const popLog = ()=>{
      logList.value.pop()
    }
    provide('logList',logList)
    provide('pushLog',pushLog)
    const { start,isTrial } = useIdentification();
    const {inputShow,inputEvent} = useInput()
    const {eventTrigger} = useEventTriggers({pushLog,inputShow,inputEvent,currentEvent,nextEvent})
    provide('eventTrigger',eventTrigger)
    const {appName,appColor,getRobotConfig} = useAppConfig({start,isTrial,pushLog,eventTrigger,logList,inputShow,inputEvent});
    const { submit} = useSubmit(pushLog,eventTrigger,inputEvent);
    const {singleChoosed,multiChoosed,appointSucceed}= useHandleOptions({logList,eventTrigger,inputEvent,inputShow,pushLog,popLog})
    const blink = ()=>{window.open("https://www.megaview.com/", "_blank");}
    useHistory({isTrial,logList,inputShow,inputEvent})
    useCommand({logList,eventTrigger,getRobotConfig})

    return {
      logList,
      inputShow,
      inputEvent,

      appName,
      appColor,

      blink,
      submit,
      singleChoosed,
      multiChoosed,
      appointSucceed,
      eventTrigger,
    };
  },
  computed:{
    // colorMain(){return `rgb(${this.appColor})`},
    // colorPlain(){return `rgb(${this.appColor},.1)`},
    // colorActive(){return `rgb(${this.appColor},.2)`},
    styleVar(){
      return {
        "--colorMain":`rgb(${this.appColor})`,
        "--colorPlain":`rgb(${this.appColor},.1)`,
        "--colorActive":`rgb(${this.appColor},.2)`

      }
    }
  }
};
</script>
<style lang="scss">
  .chat-bot{
    .header{
      background: var(--colorMain);
    }
    .bubble-customer .bubble{
      background: var(--colorMain);
      color:#fff
    }
    .bubble-options .bubble-customer .bubble{
      background: var(--colorPlain);
      color:var(--colorMain);
      &:hover{
        background: var(--colorActive);
      }
      &:active,&.choosed{
        background: var(--colorMain);
        color: #fff;
      }
    }
    .bubble-multioptions .bubble-customer .bubble{
      background: var(--colorPlain);
      color:var(--colorMain);
    }
    .appointment{
      .body .option{
        &:hover{
          background: var(--colorPlain);
          color: var(--colorMain);
        }
        &:active{
          background: var(--colorActive);
        }
      }
      .footer{
        color:var(--colorMain)
      }
    }
    .el-checkbox__input.is-focus .el-checkbox__inner{
      border-color: var(--colorMain);
    }
    .el-checkbox__inner:hover{
      border-color: var(--colorMain);
    }
    .el-checkbox__input.is-checked .el-checkbox__inner{
      background: var(--colorMain);
      border-color: var(--colorMain);
    }
    .el-button.el-button--primary{
      background: var(--colorMain);
      border: none;
      &:hover{
        opacity: .9;
      }
      &:active{
        transform: scale(.95);
      }
    }
  }
</style>
<style lang="scss" scoped>
.chat-bot {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  header {
    height: 48px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding-left: 20px;
    line-height: 48px;
  }
  .chat-body {
    flex: 1;
  }
  footer {
    .sign-row {
      text-align: center;
      font-size: 12px;
      color: #8e95b0;
      margin: 10px;
      cursor: default;
      .sign {
        color: #29304a;
        font-size: 13px;
        text-decoration: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>