import { ref, reactive, toRefs, computed, watch, provide,onMounted,onBeforeUnmount,readonly } from 'vue';
import { getTokenAsync, getBotConfig, sendEvent, getHistory } from "@/api/robot";
import consts from '@/utils/auth.js'

export function useIdentification() {
  const id = reactive({
    code: '',
    token: '',
    start: false,
    isTrial:false,

  })
  const setId = async (e) => {
    if(e.origin+'/'===document.referrer){
      if (e.data.type === 'code' && id.code !== e.data.message) {
        window.parent.postMessage({ type: 'response', message: 'success' }, '*')
        const is_trial = e.data.isTrial;
        id.isTrial = is_trial
        id.code = e.data.message
        consts.CODE = e.data.message
        const localToken = localStorage.getItem(consts.TOKENKEY)
        if (is_trial||!localToken||is_trial||localToken==='undefind'||localToken==='null') {
          const res = await getTokenAsync(is_trial)
          localStorage.setItem(consts.TOKENKEY,res.results)
          id.start = true;
        }else{
          id.start = true;
        }
      }
    }
  }
  onMounted(() => {window.addEventListener('message', setId)})
  onBeforeUnmount(() => {window.removeEventListener('message',setId)})

  return { ...toRefs(id) }
}

export function useAppConfig({ start,isTrial, pushLog,eventTrigger,logList,inputShow,inputEvent }) {
  const obj = reactive({
    appName: '小水滴',
    appColor: '68, 97, 236',
  })
    const getRobotConfig = async () => {
    const res = await getBotConfig();
    const {
      chat_bot_name,
      next_event,
      style,
    } = res.results
    window.parent.postMessage({ type: 'style', message: style }, '*')
    obj.appName = chat_bot_name;
    if(style!=='null'){
      obj.appColor = style.substring(4, style.length - 1)
    }

    
    return next_event
  }
  const readHistory = (next_event)=>{
    let history = localStorage.getItem('history')
    if(history){
      history = JSON.parse(history)
    }
    if(history&&history.length&&history[0].content!=='验证失败'){
      for(const item of history){
        pushLog(item)
      }
      const flag = localStorage.getItem('inputShow')
      inputShow.value = flag==='true'?true:false 
      inputEvent.value = localStorage.inputEvent  
    }else{
      eventTrigger(next_event)
    }
  }
  watch(start, async ()=>{
    const next_event = await getRobotConfig()
    if(isTrial.value){
      eventTrigger(next_event)
    }else{
      readHistory(next_event)
    }
  })
  return {
    getRobotConfig,
    ...toRefs(obj),
  }
}

export function useInput() {
  const data = reactive({
    inputShow: false,
    inputEvent: ''
  })
  const changeInputShow = (val) => {
    data.inputShow = val
  }
  const changeInputEvent = (val) => {
    data.inputEvent = val
  }
  provide('changeInputShow', changeInputShow)
  provide('changeInputEvent', changeInputEvent)
  return toRefs(data)

}

export function useSubmit(pushLog, eventTrigger, inputEvent) {

  const submit = async (val) => {
    pushLog({ type: 'customer', content: val })
    const res = await sendEvent({ event: inputEvent.value, content: val })
    if (!res.results) return;
    const { next_event } = res.results[0];
    inputEvent.value = next_event;
    eventTrigger(next_event, res.results[0])
  }
  return { submit }
}

export function useEventTriggers({ pushLog, inputShow, inputEvent, currentEvent, nextEvent }) {
  const begin = async () => {
    inputShow.value = false
    const res = await sendEvent({ event: 'begin', content: '' });
    if (!res.results) return;
    pushLog({ type: 'service', content: res.results[0].content })
    const options = [];
    res.results.forEach((item, index) => {
      if (index !== 0) {
        options.push({ label: item.content, value: item.next_event })
      }
    })
    pushLog({
      type: 'options',
      options
    })
  }
  const consult = async ({ content }) => {
    inputShow.value = true;
    setTimeout(()=>{
      pushLog({ type: 'service', content })

    },300)
  }
  const getName = ({ content }) => {
    inputShow.value = true;
    setTimeout(()=>{
      pushLog({ type: 'service', content })

    },300)
  }
  const getTel = ({ content }) => {
    setTimeout(()=>{
      pushLog({ type: 'service', content })

    },300)
    inputShow.value = true;
    inputEvent.value = 'get_customer_tel'
  }
  const reGetTel = ()=>{
    inputEvent.value = 'get_customer_tel'
  }
  const getEmail = ()=>{
    pushLog({type:'service',content:'您也可以输入邮箱。'})
    inputShow.value = true;
    inputEvent.value = 'get_customer_email'
  }
  const handleOptions = (arr) => {
    const res = []
    for (const item of arr) {
      res.push({ label: item, value: item })
    }
    return res
  }
  const handleMultiOptions = (arr)=>{
    const res = []
    for(const item of arr){
      res.push({label:item,value:item,isChecked:false})
    }
    return res
  }
  const reserveQuestion = ({ content, question_type, options }) => {
    setTimeout(()=>{
      pushLog({ type: 'service', content });
      switch (question_type) {
        case 'Single':
          pushLog({ type: 'options', options: handleOptions(options) })
          inputShow.value = false
          break;
        case 'Multiple':
          pushLog({type:'multioptions',options:handleMultiOptions(options)})
          inputShow.value = false
          break;
        default:
          inputShow.value = true
          break;
      }
    },300)

  }
  const appointMeetingDate = ({content})=>{
    pushLog({type:'service',content})
  }
  const appointMeetingPre = ({content})=>{
    pushLog({type:'appoint',content:{dateList:content}})
  }
  const end = ({ content }) => {
    if (content) {
      setTimeout(()=>{
        pushLog({ type: 'service', content });

      },300)
    }
    inputShow.value = false
    inputEvent.value = null
  }
  const eventTrigger = (type, results) => {
    inputEvent.value = type
    switch (type) {
      case 'begin': begin(); break;
      case 'consult': consult(results); break;
      case 'appoint_meeting_date':appointMeetingDate(results);break;
      case 'appoint_meeting_pre':appointMeetingPre(results);break;
      case 'get_customer_name': getName(results); break;
      case 'get_customer_tel': getTel(results); break;
      case 're_get_customer_tel':reGetTel();break;
      case 'get_customer_email':getEmail();break;
      case 'reserve_question': reserveQuestion(results); break;
      case 'end': end(results); break
      default:
        break;
    }
    currentEvent.value = type

  }

  return { eventTrigger }

}
export function useHandleOptions({ logList,eventTrigger, inputEvent,inputShow, pushLog ,popLog}) {
  const singleChoosed = async ({ label, value }) => {
    popLog()
    pushLog({ type: 'customer', content: label });
    if (value === 'consult') { 
      const res = await sendEvent({ event: 'consult', content: '' })
    if (!res.results) return;
      const { next_event } = res.results[0];
      inputShow.value=true;
      inputEvent.value = value
      eventTrigger(next_event, res.results[0])
      
    }else if (value === 'reserve') {
      const res = await sendEvent({event:'reserve',content:''})
      if (!res.results) return;
      inputEvent.value = value
      inputShow.value=false;
      for(const result of res.results){
        eventTrigger(result.next_event,result)
      }
    }else{
      const res = await sendEvent({ event: inputEvent.value, content: value })
    if (!res.results) return;
      const { next_event } = res.results[0];
      inputShow.value=true;
      inputEvent.value = next_event;
      eventTrigger(next_event, res.results[0])
    }
  }
  const multiChoosed = async (selections)=>{
    popLog()
    for(const item of selections){
      pushLog({type:'customer',content:item})
    }
    const res = await sendEvent({event:inputEvent.value,content:JSON.stringify(selections)})
    if (!res.results) return;
    const { next_event } = res.results[0];
      inputShow.value=true;
      inputEvent.value = next_event;
    
      eventTrigger(next_event, res.results[0])
  }
  const appointSucceed = (event,results,meetingInfo)=>{
    logList.value[logList.value.length-2].content.isComplete = true
    logList.value[logList.value.length-2].content.meetingInfo = meetingInfo
    eventTrigger(event,results)
  }
  return { singleChoosed,multiChoosed,appointSucceed }
}

export function useHistory({isTrial,logList,inputShow,inputEvent}){
  const setHistory = ()=>{
    if(localStorage.getItem(consts.TOKENKEY)){
      localStorage.history = JSON.stringify(logList.value)
      localStorage.inputShow = inputShow.value;
      localStorage.inputEvent = inputEvent.value;
    }else{
      localStorage.history = JSON.stringify([])
    }
  }
  onMounted(()=>{
    window.addEventListener('beforeunload',setHistory)
    watch(isTrial,(val)=>{
      if(val)window.removeEventListener('beforeunload',setHistory)}
      )
  })
  return {setHistory}
}

export function useCommand({logList,eventTrigger,getRobotConfig}){
  const restart = async ()=>{
    console.log('我进入restart了')
    const res = await getTokenAsync(true);
    localStorage.setItem(consts.TOKENKEY,res.results)
    logList.value.splice(0,logList.value.length)
    getRobotConfig();
    eventTrigger('begin')

  }
  const handleCommand = async (e)=>{
    if(e.data.type==='command'){
      switch (e.data.message) {
        case 'restart':
          restart();
          break;
        default:
          break;
      }
    }
  }
  onMounted(() => {
    window.addEventListener('message',handleCommand)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('message',handleCommand)
  })
}