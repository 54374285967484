<template>
  <div class="chat-input" @keypress.enter="submit">
    <input
      type="text"
      :maxlength="maxlength"
      v-model="inputValue"
      :placeholder="placeholder"
      class="input"
    />
    <button class="btn-submit" @click="submit">
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.25 9.25L17.5 3L15 16.75L6.28875 12.0038L16.25 4.25L4.74625 11.1444L1.25 9.25ZM6.25 19.25V13.3319L10 15.5L6.25 19.25Z"
          :fill="mainColor"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { computed, inject, ref, watchEffect } from "vue";
  import { ElMessage } from 'element-plus'
export default {
  props: {
    mainColor: String,
    inputEvent: String,
  },
  setup(props, { emit }) {
    const placeholder = ref("");
    const inputValue = ref("");
    const timer = ref(null);
    const maxlength = ref(50)
    let validate = null;

    var telPattern = /^1[3456789]\d{9}$/;
    var emailPattern = /[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    const eventTrigger = inject('eventTrigger')
    watchEffect(() => {
      switch (props.inputEvent) {
        case "get_customer_name":
          placeholder.value = "请输入姓名";
           maxlength.value = 10
          validate = ()=>true
          clearTimeout(timer.value)
          break;
        case "get_customer_tel":
          placeholder.value = "请输入11位手机号码";
          maxlength.value = 11
          validate = (val) => telPattern.test(val);
          if(!inputValue.value){
            timer.value = setTimeout(()=>{
              validate = (val) => emailPattern.test(val);
              eventTrigger('get_customer_email')
            },30000)
          }else{
            clearTimeout(timer.value)
          }
          break;
        case "get_customer_email":
          placeholder.value = "请输入手机号或邮箱";
          maxlength.value = 50
          validate = (val) => {
            if( emailPattern.test(val)){
              return true
            }else if(telPattern.test(val)){
              eventTrigger('re_get_customer_tel')
              return true
            }else{
              return false
            }
             
            };
          clearTimeout(timer.value)
          break;
        default:
          placeholder.value = "请输入";
          maxlength.value = 50
          validate = ()=>true
          clearTimeout(timer.value)
          break;
      }
    });
  
    const submit = () => {

      if (inputValue.value) {
        if(validate(inputValue.value)){
        emit("submit", inputValue.value);
        inputValue.value = "";
        }else{
           ElMessage.closeAll();
           ElMessage.warning('对不起，您的输入有误。');
        }
      }else{
       ElMessage.closeAll();
        ElMessage.warning('发送的内容不能为空。');
      }
    };
    return {
      placeholder,
      maxlength,
      inputValue,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat-input {
  display: flex;
  align-items: center;
  height: 56px;
  border-top: 1px solid #ebeef5;
  padding: 0 16px;
  .input {
    flex: 1;
  }
}
</style>
